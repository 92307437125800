import React from "react";
import styled from "styled-components";
import Grid from "../../styles/Grid";
import breakpoints from "../../styles/breakpoints";
import { fontFG52m, fontFG24l } from "../../styles/fonts";
import Button from "../shared/CTAButton";
import colors from "../../styles/colors";

const Title = styled.h1`
  ${fontFG52m};
  color: ${colors.white};
  text-align: center;
`;

const Description = styled.h2`
  ${fontFG24l};
  color: ${colors.white};
  text-align: center;
`;

const PrimaryButton = styled(Button)`
  width: 100%;
  @media (min-width: ${breakpoints.lg}) {
    width: auto;
  }
`;

const Buttons = styled.div``;

const Container = styled(Grid)`
  padding: 180px 0;
  background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.6) 100%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${(props) => props.url});
  background-size: cover;
  background-position: center center;

  ${Description} {
    margin-bottom: 40px;
  }

  ${Title} {
    margin-bottom: 12px;
  }

  ${Buttons} {
    grid-column: 2 / span 4;
  }

  @media (min-width: ${breakpoints.lg}) {
    ${Title} {
      grid-row: 3;
      grid-column: 5 / span 6;
    }

    ${Description} {
      grid-row: 4;
      grid-column: 5 / span 6;
    }

    ${Buttons} {
      margin: 0 auto;

      grid-row: 5;
      grid-column: 5 / span 6;
    }
  }
`;

const Hero = ({ title, description, image, buttonText, buttonUrl }) => (
  <Container url={image.url}>
    <Title>{title}</Title>
    <Description>{description}</Description>
    <Buttons>
      <PrimaryButton
        target="_"
        href={buttonUrl}
        onClick={() => {
          // window.analytics.track("Clicked Download Now", {
          //   label: "Workers Hero"
          // });
        }}
      >
        {buttonText}
      </PrimaryButton>
    </Buttons>
  </Container>
);

export default Hero;
