import React, { Component } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/shared/seo";
import Hero from "../components/workers/Hero";
import Slices from "../components/shared/Slices";

class PartnerPage extends Component {
  // componentDidMount() {
  //   window.analytics.page("Worker - Overview");
  // }

  render() {
    const {
      location,
      pageContext: { locale },
      data,
    } = this.props;
    const pageData = data.prismicPartnerPage.data;
    return (
      <Layout location={location} locale={locale} pageData={pageData}>
        <Seo
          title={`${pageData.meta_title} | Workmate`}
          description={pageData.meta_description}
          image={pageData.hero_image.url}
        />
        <Hero
          title={pageData.hero_title}
          description={pageData.hero_description}
          image={pageData.hero_image}
          buttonText={pageData.button_text}
          buttonUrl={pageData.button_url}
        />
        <Slices slices={pageData.body} />
      </Layout>
    );
  }
}

export default PartnerPage;

export const query = graphql`
  query PartnerQuery($localeKey: String) {
    prismicPartnerPage(lang: { eq: $localeKey }) {
      data {
        meta_title
        meta_description
        navigation_cta_button_text
        navigation_cta_button_url
        hero_title
        hero_description
        hero_image {
          alt
          url
        }
        button_text
        button_url
        body {
          ... on PrismicPartnerPageDataBodyListsInGrid {
            slice_type
            items {
              title
              paragraph {
                raw
              }
            }
            primary {
              title
              description
              lists_button_url
              lists_desktop_button_text
              lists_hubspot_form_id
              lists_mobile_button_text
              image {
                alt
                url
              }
            }
          }
          ... on PrismicPartnerPageDataBodySteps {
            slice_type
            items {
              button_text
              button_url
              description
              image {
                alt
                url
                gatsbyImageData
              }
              title
            }
            primary {
              title
            }
          }
          ... on PrismicPartnerPageDataBodyLogos {
            slice_type
            items {
              logo {
                alt
                url
                gatsbyImageData
              }
            }
            primary {
              background_color
              description
              title
            }
          }
          ... on PrismicPartnerPageDataBodyQuotes {
            slice_type
            items {
              job_position
              name_age
              quote
            }
            primary {
              background_color
              description
              title
            }
          }
          ... on PrismicPartnerPageDataBodyCtaBannerWithImage {
            slice_type
            primary {
              background_image {
                alt
                url
              }
              button_text
              button_url
              description
              title
              title_colored
            }
          }
        }
      }
    }
    prismicSite(lang: { eq: $localeKey }) {
      data {
        clients {
          logo {
            alt
            copyright
            url
          }
          name
        }
      }
    }
  }
`;
